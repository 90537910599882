


























































































































































































import { Component, Vue } from 'vue-property-decorator'
import AppNavDrawer from '@/components/App/AppNavDrawer.vue'
import ProductPictureUpload from '@/components/ProductPictureUpload.vue'
import axiosInstance from '@/plugins/axios'
import Swal from 'sweetalert2'

@Component({
  components: {
    AppNavDrawer,
    ProductPictureUpload
  }
})
export default class ProductEdit extends Vue {
  productTypes = []
  product: any | null = null

  created () {
    this.fetchTypes()
    this.fetchProduct()
  }

  async fetchTypes (): Promise<void> {
    try {
      const { data } = await axiosInstance.get('/manager/types')
      console.log('PRODUCT_TYPES', data)
      this.productTypes = data
    } catch (error) {
      console.error(error)
    }
  }

  async fetchProduct (): Promise<void> {
    try {
      this.$store.dispatch('app/setIsLoading', true)
      const res = await axiosInstance.get(`/manager/product/${this.$route.params.productId}`)
      const data: any = res.data
      console.log('PRODUCT', data)

      this.product = {
        id: data.id,
        name: data.name,
        brand: data.brand,
        typeId: data.typeid,
        description: data.description,
        pictures: data.pictures,
        models: data.models.map(model => {
          return {
            id: model.id,
            sku: model.sku,
            price: model.price,
            originalPrice: model.originalprice,
            model: model.model,
            color: model.color,
            storage: model.storage,
            size: model.size,
            pictures: model.pictures
          }
        })
      }
      this.$store.dispatch('app/setIsLoading', false)
    } catch (error) {
      console.error(error)
      this.$store.dispatch('app/setIsLoading', false)
    }
  }

  addModel (): void {
    // @ts-ignore
    this.product.models.push({
      sku: null,
      price: null,
      originalPrice: null,
      model: null,
      color: null,
      storage: null,
      size: null,
      pictures: []
    })
  }

  removeModel (index: number): void {
    this.product.models.splice(index, 1)
  }

  async saveProduct (): Promise<void> {
    try {
      this.$store.dispatch('app/setIsLoading', true)
      const res = await axiosInstance.post('/manager/saveproduct', {
        id: this.product.id,
        name: this.product.name,
        brand: this.product.brand,
        typeid: this.product.typeId,
        description: this.product!.description,
        pictures: this.product!.pictures,
        models: this.product.models.map(model => {
          return {
            id: model.id,
            sku: model.sku,
            // @ts-ignore
            price: parseInt(model.price ?? 0),
            // @ts-ignore
            originalprice: parseInt(model.originalPrice ?? 0),
            model: model.model,
            color: model.color,
            storage: model.storage,
            size: model.size,
            pictures: model.pictures
          }
        })
      })
      const data: any = res.data
      console.log('SAVE_PRODUCT_RESULT', data)
      this.$store.dispatch('app/setIsLoading', false)

      if (data.result !== 'OK') {
        Swal.fire({
          icon: 'error',
          title: data.message
        })
        return
      }

      Swal.fire({
        icon: 'success',
        title: 'ບັນທຶກສຳເລັດ'
      })
    } catch (error) {
      console.error(error)
      this.$store.dispatch('app/setIsLoading', false)

      Swal.fire({
        icon: 'error',
        title: 'ກະລຸນາລອງໃໝ່ອີກຄັ້ງ'
      })
    }
  }
}
